<template>
    <div class="item">
        <div class="item-main">
            <div class="item-pic">
                <img :src="'/images/website/' + obj.image_url" alt="" />
            </div>
            <div class="item-txt-box">
                <div class="item-title">{{obj.name}}</div>
                <div class="item-time">{{obj.time.replace('T',' ')}}</div>
                <div class="item-brief">{{obj.introduction}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "item",
        props:{
            obj:{
                type:Object,
                required:true,
            },
        },
    }
</script>

<style scoped>
    .item{
        width: 300px;
        margin-right: 30px;
        margin-bottom: 30px;
        border: 1px solid #ccc;
        border-radius: 10px;
        overflow: hidden;
        transition: .5s ease all;
    }
    .item:hover{
        transform: scale(1.05);
        box-shadow: 0 1px 2px 2px #f3f3f3;
    }
    .item .item-main{}
    .item .item-main .item-pic{
        height: 150px;
    }
    .item .item-main .item-pic img{
        width: 100%;
        height: 100%;
    }
    .item .item-main .item-txt-box{
        padding: 0 20px;
        box-sizing: border-box;
        height: 319px;
        line-height: 30px;
    }
    .item .item-main .item-txt-box .item-title{
        font-size: 19px;
        color: #333;
        font-weight: 700;
        height: 115px;
        padding-top: 20px;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .item .item-main .item-txt-box .item-time{
        color: #999;
        margin-bottom: 18px;
    }
    .item .item-main .item-txt-box .item-brief{
        color: #666;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>