<template>
    <div class="news">
        <div class="news-title">新闻中心</div>
        <div class="news-tab">
            <tab :tabs="tabs" :cur="cur" @changeCur="changeCur" />
        </div>
        <div class="news-main">
            <item v-for="(item,index) in list" :obj="item" />
        </div>
    </div>
</template>

<script>
    import tab from '@/components/common/tab'
    import item from '@/components/news/item'
    export default {
        name: "News",
        components:{
            tab,
            item,
        },
        data(){
            return{
                cur:'0',
                tabs:[],      //tab数据
                list:[],
            }
        },
        created(){
            if(this.$route.params.index){
                this.cur = this.$route.params.index;
            }else{
                this.cur = '0';
            }
            this.getNavs();
        },
        methods:{
            //获取头部
            async getNavs(){
                if(this.$store.getters.getNavsData){
                    const navs = JSON.parse(JSON.stringify(this.$store.getters.getNavsData));
                    for(let i=0;i<navs.length;i++){
                        if(navs[i].id == 5){
                            if(navs[i].category){
                                this.tabs = navs[i].category;
                                if(this.tabs[this.cur].arr){
                                    this.list = this.tabs[this.cur].arr;
                                }else{
                                    this.list = [];
                                }
                            }
                            break;
                        }
                    }
                }else{
                    try {
                        const {data} = await this.$axios({
                            url:'/api/firsttitle'
                        });
                        if(data.code){
                            this.$store.dispatch('setNavsData',data.data);

                            for(let i=0;i<data.data.length;i++){
                                if(data.data[i].id == 5){
                                    if(data.data[i].category){
                                        this.tabs = data.data[i].category;
                                        if(this.tabs[this.cur].arr){
                                            this.list = this.tabs[this.cur].arr;
                                        }else{
                                            this.list = [];
                                        }
                                    }
                                }
                            }
                        }
                    }catch (e) {
                        console.log(e);
                    }
                }
            },
            changeCur(index){
                this.cur = index + '';
                this.getNavs();
            },
        },
    }
</script>

<style scoped>
    .news{
        width: 1000px;
        margin: 0 auto;
        /*background-color: #cccccc;*/
        padding: 30px 0;
        box-sizing: border-box;
    }
    .news .news-title{
        font-size: 40px;
        line-height: 64px;
        text-align: center;
        margin-bottom: 20px;
    }
    .news .news-tab{
        margin-bottom: 50px;
    }
    .news .news-main{
        display: flex;
        /*justify-content: space-between;*/
        flex-wrap: wrap;
    }
</style>