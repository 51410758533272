<template>
    <div class="tabs">
        <ul>
            <li v-for="(item,index) in tabs" :class="index==cur?'curr':''">
                <span @click="changeCur(index)">{{item.name}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "tab",
        props:{
            tabs:{
                type:Array,
                required:true,
            },
            cur:{
                type:String,
                required:true,
            },
        },
        methods:{
            changeCur(index){
                this.$emit('changeCur',index);
            },
        },
    }
</script>

<style scoped>
    .tabs{}
    .tabs ul{
        display: flex;
    }
    .tabs ul li{
        flex: 1;
        text-align: center;
        line-height: 60px;
    }
    .tabs ul li.curr{
        border-bottom: 2px solid #333;
    }
    .tabs ul li span{
        cursor: pointer;
        color: #333;
        font-weight: 700;
    }
</style>